import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

require.context("../images", true);
import Modal from "bootstrap/js/dist/modal";

import "../stylesheets/application.scss";

window.addEventListener("load", () => {
  const subscribeForm = document.querySelectorAll(".subscribe-form");
  const toast = document.querySelector(".toast");
  const toastText = document.querySelector(".toast .toast-header__text");
  const toastClose = document.querySelector(".toast .close");

  subscribeForm.forEach((form) => {
    const button = form.querySelector(".btn");
    const input = form.querySelector(".form-control");

    $(form)
      .on('ajax:beforeSend', function() {
        button.disabled = true;
      })
      .on('ajax:success', function () {
        button.disabled = false;
        input.value = ""; 
        
        const subscribeModal = form.closest(".modal");
        const closeBtn = document.querySelector(".btn-close");
        if (subscribeModal) {
          closeBtn.click();
        }

        toast.classList.add("success");

        toastText.innerHTML = "Success";

        setTimeout(() => {
          toast.classList.remove("success");
        }, 3000);
      })
      .on('ajax:error', function() {
          toastText.innerHTML = "Error";
          button.disabled = false;
          toast.classList.add("error");

          setTimeout(() => {
            toast.classList.remove("error");
          }, 3000);
      })
  })

  toastClose.addEventListener("click", (event) => {
    if (toast.classList.contains("success")) {
      toast.classList.remove("success");
    }

    if (toast.classList.contains("error")) {
      toast.classList.remove("error");
    }
  });
});
